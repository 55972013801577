<template>
  <!-- <v-container
    class="fill-height parnert"
    fluid
    style="min-height: 434px"
  >
    <v-row>
            <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
            >
                <h1 id="parnert"
                class="display-2 text-uppercase text-center"
                >Somos miembros de</h1>
            </v-col>
    </v-row>
 <v-fade-transition mode="out-in">
        
      <v-row>
         <v-col cols="12"
                lg="6"
                md="6"
                sm="12"
            >
          <v-card 
          class="parnert"
          flat>
            <v-img
              src="../assets/img/logo.jpg"
              max-height="110"
              contain
              class=""
            ></v-img>
           <v-card-subtitle class="text-center">Unversidad Nacional de Ingeniería</v-card-subtitle>
          </v-card>
        </v-col>
         <v-col cols="12"
                     lg="6"
                    md="6"
                    sm="12"
         >
          <v-card 
          class="parnert"
          flat
          >
            <v-img
              src="../assets/img/LOGO.png"
              max-height="110"
              contain
              class=""
            ></v-img>
            <v-card-subtitle class="text-center">
             LACTLD
            </v-card-subtitle>
          </v-card>
        </v-col>
         <v-col cols="12"
                     lg="6"
                    md="6"
                    sm="12"
         >
          <v-card 
          class="parnert"
          flat
          >
            <v-img
              src="../assets/img/icann.png"
              max-height="125"
              contain
              class=""
            ></v-img>
            <v-card-subtitle class="text-center">
            ICANN
            </v-card-subtitle>
          </v-card>
        </v-col>
          <v-col cols="12"
                     lg="6"
                    md="6"
                    sm="12"
         >
          <v-card 
          class="parnert"
          flat
          >
            <v-img
              src="../assets/img/iana.png"
              max-height="125"
              contain
              class=""
            ></v-img>
            <v-card-subtitle class="text-center">
            ICANN
            </v-card-subtitle>
          </v-card>
        </v-col>
          <v-col cols="12"
                     lg="6"
                    md="6"
                    sm="12"
         >
          <v-card 
          class="parnert"
          flat
          >
            <v-img
              src="../assets/img/lacnic.png"
              max-height="125"
              contain
              class=""
            ></v-img>
            <v-card-subtitle class="text-center">
           LACNIC
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition> 

    <v-row>
            <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
            >
                <h4 id="parnert"
                class="display-5 text-uppercase text-center"
                >AYUDANDO AL DESARROLLO DEL INTERNET DE NICARAGUA 
                DESDE 1988</h4>
            </v-col>
    </v-row>
  </v-container>  -->
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h1 id="parnert" class="display-1 text-uppercase text-center">SOMOS MIEMBROS DE:</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/logouni12.png"
                class="mr-4"
                max-width="110"
                min-width="64"
              ></v-img>

              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">
                    Universidad Nacional de Ingeniería
                  </h3>
                  <span class="font-weight-regular caption"
                    >www.uni.edu.ni</span
                  >
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/LOGO.png"
                class="mr-4"
                max-width="125"
                min-width="64"
              ></v-img>

              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">LACTLD</h3>
                  <span class="font-weight-regular caption"
                    >www.lactld.org</span
                  >
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/icann.png"
                class="mr-4"
                max-width="125"
                min-width="64"
              ></v-img>

              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">ICANN</h3>
                  <span class="font-weight-regular caption">www.icann.org</span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/iana2.png"
                class="mr-4"
                max-width="130"
                min-width=""
              ></v-img>

              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">
                    Internet Assigned Numbers Authority
                  </h3>
                  <span class="font-weight-regular caption">www.iana.org</span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/lacnic.png"
                class="mr-4"
                max-width="125"
                min-width="64"
              ></v-img>

              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">LACNIC</h3>
                  <span class="font-weight-regular caption"
                    >www.lacnic.net</span
                  >
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card flat class="mx-auto mt-4" max-width="450">
          <v-card-text class="py-0"> </v-card-text>
          <v-list three-line>
            <v-list-item ripple>
              <v-img
                src="../assets/img/internet-society-isoc.png"
                class="mr-4"
                max-width="125"
                min-width="64"
              ></v-img>
              <v-list-item-content>
                <div class="text-uppercase">
                  <h3 class="font-weight-regular">Internet Society</h3>
                  <span class="font-weight-regular caption"
                    >www.internetsociety.org</span
                  >
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <h4 id="parnert" class="display-5 text-uppercase text-center">
          PROMOVIENDO EL DESARROLLO DEL INTERNET DE NICARAGUA DESDE 1988
        </h4>
      </v-col>
      <v-col cols="12">
        <!-- <v-parallax src="../assets/img/coronavirus_spanish.jpg">
        </v-parallax> -->
        <v-img
          lazy-src="../assets/img/coronavirus_spanish.jpg"
          src="../assets/img/coronavirus_spanish.jpg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.parnert {
  background-color: #e0e0e0 !important;
}
#parnert {
  margin-top: 60px;
  margin-bottom: 30px;
}
.v-application a {
  color: #000;
}
</style>
